<template>
  <f-card class="feature-operation-modal-card">
    <f-datatable
      ref="featureSortingOperationsDatatable"
      class="feature-sorting-operations-table"
      :columns="featureSortingOperationsColumns"
      data-url="/features/sortingOperations"
      :features="{ fullscreen: false, showColumns: false }"
      :query-params="{ projectId: selectedProjectId }"
    />
  </f-card>
</template>

<script>
import { featureSortingOperationsColumns } from '@/data/columns'
import { mapGetters } from 'vuex'

export default {
  data() {
    return { featureSortingOperationsColumns }
  },
  computed: {
    ...mapGetters(['selectedProjectId']),
  }
}
</script>

<style lang="scss">
.feature-operation-modal-card{
  .card-body {
    padding: 0 !important;
  }
  .fixed-table-pagination {
    padding: 0 1rem;
  }
}
.feature-sorting-operations-table {
  table {
    border-left: none;
    border-right: none;
    th {
      border-left: none;
      border-right: none;
      &:nth-of-type(1) {
        text-align: center;
      }
    }

    tbody {
      & > tr {
        border-left: none;
        border-right: none;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        & > td {
          border: none;
          &:nth-of-type(1) {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
