<template>
  <div>
    <f-row>
      <f-col
        class="required"
        md="12"
      >
        <f-select
          v-model="feature.status"
          :label="$t('labels.status')"
          :options="featureStatuses"
          rules="required"
          value-type="string"
        />
      </f-col>
      <f-col
        v-if="feature.status !== featureStatus.REJECTED"
        md="6"
      >
        <f-input
          v-model="feature.startDate"
          :label="$t('labels.startDate')"
          type="date"
        />
      </f-col>
      <f-col
        v-if="feature.status !== featureStatus.REJECTED"
        md="6"
      >
        <f-input
          v-model="feature.endDate"
          :label="$t('labels.endDate')"
          type="date"
        />
      </f-col>
      <f-col
        v-if="
          (feature.endDate !== this.unmodifiedEndDate &&
            this.unmodifiedEndDate) ||
            (feature.startDate !== this.unmodifiedStartDate &&
              this.unmodifiedStartDate)
        "
        md="12"
      >
        <f-input
          v-model="feature.changeDateReason"
          :label="$t('labels.changeDateReason')"
          multiline
        />
      </f-col>
      <f-col
        v-if="
          feature.status === featureStatus.TESTING ||
            feature.status === featureStatus.TEST_APPROVED
        "
        md="12"
      >
        <f-row>
          <f-col>
            <label for="selectRepo">{{ $t('labels.repository') }}</label>
            <f-select
              v-model="feature.staging"
              :key="'staging'"
              :data-params="{
                projectId: feature.project.id,
                hasDeploymentUrl: true
              }"
              data-url="/projects/repositories"
              :placeholder="$t('labels.selectRepo')"
              :settings="{ allowClear: false }"
              @change="onChangeRepositoryUrl($event, 'staging')"
            />
          </f-col>
          <f-col
            class="required"
            md="6"
          >
            <f-input
              v-model="feature.stagingLink"
              :label="$t('labels.stagingLink')"
              rules="required"
            >
              <template #append>
                <a
                  :href="feature.stagingLink"
                  target="_blank"
                >
                  <f-button
                    icon="external-link"
                    variant="info"
                  />
                </a>
              </template>
            </f-input>
          </f-col>
        </f-row>
        <f-input
          v-model="feature.stagingNote"
          :label="$t('labels.stagingNote')"
          multiline
        />
      </f-col>
      <f-col
        v-if="feature.status === featureStatus.DONE"
        md="12"
      >
        <f-row>
          <f-col>
            <label for="selectRepo">{{ $t('labels.repository') }}</label>
            <f-select
              v-model="feature.prod"
              :key="'prod'"
              :data-params="{
                projectId: feature.project.id,
                hasDeploymentUrl: true
              }"
              data-url="/projects/repositories"
              :placeholder="$t('labels.selectRepo')"
              :settings="{ allowClear: false }"
              @change="onChangeRepositoryUrl($event, 'prod')"
            />
          </f-col>
          <f-col
            class="required"
            md="6"
          >
            <f-input
              v-model="feature.prodLink"
              :label="$t('labels.prodLink')"
              rules="required"
            >
              <template #append>
                <a
                  :href="feature.prodLink"
                  target="_blank"
                >
                  <f-button
                    icon="external-link"
                    variant="info"
                  />
                </a>
              </template>
            </f-input>
          </f-col>
        </f-row><f-input
          v-model="feature.prodNote"
          :label="$t('labels.prodNote')"
          multiline
        />
      </f-col>
      <f-col
        v-if="feature.status === featureStatus.REJECTED"
        md="12"
      >
        <f-input
          v-model="feature.rejectedReason"
          :label="$t('labels.rejectedReason')"
          multiline
        />
      </f-col>
      <f-col v-if="feature.status !== featureStatus.REJECTED">
        <f-input
          v-model="feature.estimatedEffort"
          :label="$t('labels.estimatedEffort')"
          type="number"
        >
          <template #append>
            <b-input-group-text
              class="effort-input-group-text"
            >
              {{ $t('labels.personHours') }}
            </b-input-group-text>
          </template>
        </f-input>
      </f-col>
      <f-col
        v-if="isRenderRealEffort"
      >
        <f-input
          v-model="feature.realEffort"
          :label="$t('labels.realEffort')"
          type="number"
        >
          <template #append>
            <b-input-group-text
              class="effort-input-group-text"
            >
              {{ $t('labels.personHours') }}
            </b-input-group-text>
          </template>
        </f-input>
      </f-col>
    </f-row>
  </div>
</template>

<script>
import { featureStatus } from '@/data/enums'

export default {
  props: {
    feature: { type: Object }
  },

  data() {
    return {
      featureStatus,
      featureStatuses: [
        {
          id: featureStatus.WAITING,
          name: this.$i18n.t('labels.waitingFeature')
        },
        {
          id: featureStatus.APPROVED,
          name: this.$i18n.t('labels.approvedFeature')
        },
        {
          id: featureStatus.IN_PROGRESS,
          name: this.$i18n.t('labels.inProgress')
        },
        { id: featureStatus.TESTING, name: this.$i18n.t('labels.testing') },
        {
          id: featureStatus.TEST_APPROVED,
          name: this.$i18n.t('labels.testApproved')
        },
        { id: featureStatus.REJECTED, name: this.$i18n.t('labels.rejected') },
        { id: featureStatus.DONE, name: this.$i18n.t('labels.done') }
      ],
      unmodifiedStartDate: null,
      unmodifiedEndDate: null
    }
  },
  computed: {
    isRenderRealEffort() {
      return !([
        featureStatus.WAITING,
        featureStatus.APPROVED,
        featureStatus.IN_PROGRESS,
        featureStatus.REJECTED,
      ].includes(this.feature.status))
    }
  },
  created() {
    this.unmodifiedStartDate = this.feature.startDate
    this.unmodifiedEndDate = this.feature.endDate
  },
  methods: {
    async onChangeRepositoryUrl(id, type) {
      const { stagingUrl, productionUrl } = await this.$http.get(
        `/projects/repositories/${id}`
      )

      type === 'staging'
        ? (this.feature.stagingLink = stagingUrl)
        : (this.feature.prodLink = productionUrl)
    }
  }
}
</script>

<style
  lang="scss"
  scoped
>
.effort-input-group-text {
  font-size: 0.8125rem !important;
}
</style>
